import React from 'react';
import { Box, Text } from '../../core';
import { Button } from "@ui/components/core/button"

type Props = {
  children: React.ReactNode;
  menuLinks?: string[];
};

export default function TruncateText({ menuLinks, ...props }: Props): React.ReactElement {
  const [isTruncated, setIsTruncated] = React.useState(true);

  const showReadButton = (text: string | null) => {
    const match = text?.match(/<\/(p|div)>/i);
    const extractedText = text
      ? text.substring(0, match && match.index ? match.index + match[0].length : text.length).replace(/<[^>]+>/g, '')
      : '';
    return extractedText.length > 150;
  };

  const removeHtmlTags = (text: string | null) => text !== null && text.replace(/(<([^>]+)>)/gi, '');
  return (
    <Box className="w-full flex flex-col md:flex-row md:gap-4">
      <Text
        {...props}
        className="text-[12px] [word-spacing:1.5px] leading-[21px]"
        truncate={isTruncated ? 'end' : undefined}
      >
        {isTruncated ? (
          removeHtmlTags(props.children as string)
        ) : (
          <>
            <style>
              {`
                .truncateRichText a{
                  text-decoration: underline;
                }
              `}
            </style>
            <div className="truncateRichText"dangerouslySetInnerHTML={{ __html: props.children as string }} />
            <div className="py-4">
              {menuLinks
                ?.filter(Boolean)
                .map((link) => (
                  <Button
                    key={link}
                    className="items-baseline text-[12px] md:text-[12px] capitalize justify-start pr-2"
                    variant="unstyled"
                    onClick={() => setIsTruncated(!isTruncated)}
                    dangerouslySetInnerHTML={{ __html: link }}
                  ></Button>
                ))}
            </div>
          </>
        )}
      </Text>
      {!!showReadButton(props.children as string) && (
        <Button
          className="items-baseline min-w-[96px] text-[12px] md:text-[12px] capitalize justify-start"
          variant="unstyled"
          onClick={() => setIsTruncated(!isTruncated)}
        >
          {isTruncated ? 'Read more' : 'Read less'}
        </Button>
      )}
    </Box>
  );
}
