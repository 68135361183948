import { useQuery } from '@tanstack/react-query';
import { personalizedRecommendations } from '@ui/axios/searchSpring';
import { ProductCardCarousel } from '@ui/components/shared';
import { recommendationProductsToSearchProducts } from '@ui/helpers.export';
import { getCookie } from '@ui/hooks/useCookie';
import HomeRecentlyViewedSection from '../../home/HomeRecentlyViewedSection/HomeRecentlyViewedSection';

export default function CollectionEmptySection() {
  const { data: recommendations } = useQuery({
    queryKey: ['collectionPageRecommendations'],
    queryFn: async () => {
      const res = await personalizedRecommendations({
        tags: 'home-page-new',
        shopper: getCookie('shopper'),
        cart: getCookie('cart'),
        lastViewed: getCookie('lastViewed'),
      });

      return recommendationProductsToSearchProducts(res.data[0].results);
    },
  });

  return (
    <div className="w-full max-w-[1800px] px-4 md:px-8 mx-auto pb-12">
      <div>
        <div className="uppercase text-[20px] tracking-[0.25px] font-semibold">Sorry,</div>
        <div className="uppercase tracking-[0.04em] font-semibold mt-2">
          There are no products matching your request.
        </div>
        <div className="tracking-[0.4px] mt-2">Try adjusting your search or discover our latest releases below.</div>
      </div>
      <div className="mt-12">
        <div className="font-semibold tracking-[0.04%] uppercase mb-4">New</div>
        <div className='md:px-2'>
          <ProductCardCarousel
            collection="new"
            withControls
            className='px-2 md:px-0'
            carousel={{
              tag: 'home-page-new',
              placement: 'home-page',
              products: recommendations || [],
            }}
            swiperOptions={{
              spaceBetween: 16,
              slidesPerView: 1.2,
              breakpoints: {
                640: { slidesPerView: 2.2 },
                768: { slidesPerView: 3.2 },
                1024: { slidesPerView: 4.2 },
                1200: { slidesPerView: 5.2 },
            }}}
            height={570}
            width={380}
          />
        </div>
      </div>
      <div className="mt-12">
        <HomeRecentlyViewedSection 
          carouselRootClassName="px-2"
          swiperOptions={{
            spaceBetween: 16,
            slidesPerView: 1.2,
            breakpoints: {
              640: { slidesPerView: 2.2 },
              768: { slidesPerView: 3.2 },
              1024: { slidesPerView: 4.2 },
              1200: { slidesPerView: 5.2 },
          }}}
        />
      </div>
    </div>
  );
}
