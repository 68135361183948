import Link from 'next/link';
import cn from '@ui/utils/cn';
import { SwiperCarousel, SwiperRef, SwiperSlide } from '@ui/components/core/carousel';
import { useRef } from 'react';
import { SwiperOptions } from 'swiper/types';

interface FilterImageLink {
  image: string;
  imageAlt?: string;
  text: string;
  href: string;
}

interface FilterImageCarouselProps {
  links: FilterImageLink[];
  title?: string;
  className?: string;
  swiperOptions?: SwiperOptions;
}

const FilterImageCarousel = ({ links, title, className, swiperOptions}: FilterImageCarouselProps) => {
  const swiperRef = useRef<SwiperRef>(null);
  return (
    <div className={className}>
      {title && <h5 className='text-sm font-bold mb-4'>{title}</h5>}
      <div className={cn('relative flex gap-2 sm:gap-3 overflow-x-auto scrollbar-none')}> 
        <SwiperCarousel parentRef={swiperRef} 
          {...swiperOptions}
        >
          {links.map((link, index) => (
            <SwiperSlide key={link.text + 'Filter Image Carousel' + index} autoHeight>
              <div
                className='w-full relative overflow-hidden rounded-[0.25rem]'
              >
                <Link href={link.href} className='block w-full pb-[150%]'>
                  <picture className="absolute w-full left-1/2 -translate-x-1/2 object-cover origin-top-left transition-all duration-300 group-hover:scale-110">
                    <img src={link.image} alt={link.imageAlt || link.text} />
                  </picture>
                  <div
                    className='h-[3rem] w-full absolute top-0 left-0 p-[0.25rem] text-white font-bold uppercase text-xs bg-gradient-to-b from-[#00000040] lg:p-[0.375rem]'
     
                  >
                    <span className="group-hover:underline">{link.text}</span>
                  </div>
                </Link>
              </div>
            </SwiperSlide>
          ))}
        </SwiperCarousel>
      </div>
    </div>
  );
};

export default FilterImageCarousel;
