/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
import { useEffect, useState, useMemo } from 'react';
import { setCookie } from '@ui/hooks/useCookie';
import { ProductCard, ProductCardSkeleton } from '../../../shared';
import useCartStore from '@ui/store/cartStore';
import { Result } from '@ui/types/mappings/Collections';
import { chunk } from 'lodash';
import { CollectionInlineBannerMappings } from '@ui/types/mappings/CollectionBannerMappings.type';
import { useRouter } from 'next/router';
import { InView } from 'react-intersection-observer';
import { useGetCart } from '@ui/hooks/useCartQuery';
import { useQuery } from '@tanstack/react-query';
import { GetProducts } from '@client-shopify/gql/storefront/api/queries';
import { getCustomerCountry } from '@ui/hooks/useCustomerCountry';

type SaleFilters = '15' | '30' | '50';

type CollectionGallerySectionProps = {
  collection: string;
  products: Array<Result>;
  saleFilter?: SaleFilters | null;
};
const SUBSET_SIZE = 4;
const CollectionGallerySection = ({ collection, products }: CollectionGallerySectionProps) => {
  const [imageErrorMap, setImageErrorMap] = useState<{ [key: string]: boolean }>({});
  const cartID = useCartStore((state) => state?.cartId);
  const cartUpdatedKey = useCartStore((state) => state?.cartUpdatedKey);

  const { data: getCartQueryResults } = useGetCart({
    refreshKey: cartUpdatedKey || '',
    cartId: cartID || '',
  });

  useEffect(() => {
    if (!cartUpdatedKey) return;
    if (getCartQueryResults?.cart?.cartLines && getCartQueryResults.cart.cartLines.length) {
      const cartString = getCartQueryResults.cart.cartLines.map((cartProduct) => cartProduct.sku).join(',');
      setCookie('cart', cartString);
    }
  }, [cartUpdatedKey, getCartQueryResults]);
  const { data: shopifyProducts, isFetched } = useQuery({
    queryKey: [products.map((product) => product.id)],
    initialData: products,
    staleTime: 0,
    queryFn: async () => {
      const productsFromShopify = await GetProducts({
        first: products.length,
        query: products.map((p) => `id:${p.uid}`).join(' OR '),
        country: getCustomerCountry(),
      });
      return products.map((product) => {
        const productEdges = productsFromShopify?.data?.products.edges || [];
        const productNode = productEdges.find((p) => p.node.id.split('/').pop() === product.uid);
        if (!productNode) return product;
        return {
          ...product,
          price: productNode.node.priceRange.maxVariantPrice.amount,
          currency: productNode.node.priceRange.maxVariantPrice.currencyCode,
          variant_compare_at_price: productNode.node.compareAtPriceRange.maxVariantPrice.amount,
          images: productNode.node.images.edges.map((image) => image.node.url),
          imageUrl: productNode.node.images.edges[0]?.node.url || product.imageUrl,
        };
      });
    },
  });
  useEffect(() => {
    setImageErrorMap({});
    if (!isFetched) return;
    const validateImageUrl = async (url: string, index: number) => {
      try {
        const response = await fetch(url, { method: 'HEAD', priority: 'low' });
        if (!response.ok) {
          throw new Error('Image not found');
        }
      } catch (error) {
        setImageErrorMap((prev) => ({ ...prev, [index]: true }));
      }
    };
    shopifyProducts.forEach((product, index) => {
      if (product.imageUrl) {
        validateImageUrl(product.imageUrl, Math.floor(index / SUBSET_SIZE));
      } else {
        setImageErrorMap((prev) => ({ ...prev, [Math.floor(index / SUBSET_SIZE)]: true }));
      }
    });
  }, [isFetched]);
  const router = useRouter();
  const currentPage = Number(router.query.page || 1);
  const collectionBanners = currentPage === 1 ? CollectionInlineBannerMappings[collection] || [] : [];
  const desktopBanners = useMemo(
    () => collectionBanners.filter((banner) => banner.viewport === 'desktop'),
    [collectionBanners],
  );
  const mobileBanners = useMemo(
    () => collectionBanners.filter((banner) => banner.viewport === 'mobile'),
    [collectionBanners],
  );

  return (
    <div className="grid grid-cols-12 grid-flow-row-dense gap-x-3 md:gap-x-6 gap-y-6 md:gap-y-12">
      {chunk(shopifyProducts, SUBSET_SIZE).map((subset, subsetIndex) => {
        let rowCountInDesktop = 0;
        let rowCountInMobile = 0;
        const currentDesktopRow = rowCountInDesktop + 1;
        const desktopBanner = desktopBanners.find((banner) => banner.rowPosition === currentDesktopRow);
        rowCountInDesktop += desktopBanner ? 2 : 1;

        const [mobileBanner1, mobileBanner2] = chunk(subset, 2).map(() => {
          const currentMobileRow = rowCountInMobile + 1;
          const mobileBanner = mobileBanners.find((banner) => banner.rowPosition === currentMobileRow);
          rowCountInMobile += mobileBanner ? 2 : 1;
          return { banner: mobileBanner, row: currentMobileRow };
        });

        // Add condition to check if it's first two rows
        const isFirstTwoRows = subsetIndex < 2;

        return (
          <InView key={subset[0].id} triggerOnce={true} initialInView={isFirstTwoRows}>
            {({ inView, ref }) => (
              <>
                {desktopBanner && (
                  <div className="col-span-12 pb-3 hidden md:block" style={{ gridRowStart: currentDesktopRow }}>
                    <picture>
                      <img
                        className="w-full h-auto"
                        src={desktopBanner.image}
                        width={desktopBanner.width}
                        height={desktopBanner.height}
                        alt={desktopBanner.alt}
                      />
                    </picture>
                  </div>
                )}

                {mobileBanner1?.banner && (
                  <div className="col-span-12 pb-3 md:hidden" style={{ gridRowStart: mobileBanner1.row }}>
                    <picture>
                      <img
                        className="w-full h-auto"
                        src={mobileBanner1.banner.image}
                        width={mobileBanner1.banner.width}
                        height={mobileBanner1.banner.height}
                        alt={mobileBanner1.banner.alt}
                      />
                    </picture>
                  </div>
                )}

                {mobileBanner2?.banner && (
                  <div className="col-span-12 pb-3 md:hidden" style={{ gridRowStart: mobileBanner2.row }}>
                    <picture>
                      <img
                        className="w-full h-auto"
                        src={mobileBanner2.banner.image}
                        width={mobileBanner2.banner.width}
                        height={mobileBanner2.banner.height}
                        alt={mobileBanner2.banner.alt}
                      />
                    </picture>
                  </div>
                )}

                {!imageErrorMap[subsetIndex] && (
                  <>
                    {subset.map((product, i) => (
                      <div
                        key={product.handle}
                        className="col-span-6 md:col-span-4 xl:col-span-3"
                        ref={i === 0 ? ref : undefined}
                      >
                        {inView || isFirstTwoRows ? (
                          <ProductCard
                            collection={collection}
                            product={product as any}
                            href={
                              collection
                                ? `/collections/${collection}/products/${product.handle}/`
                                : `/products/${product.handle}/`
                            }
                            height={420}
                            width={300}
                          />
                        ) : (
                          <ProductCardSkeleton />
                        )}
                      </div>
                    ))}
                  </>
                )}
              </>
            )}
          </InView>
        );
      })}
    </div>
  );
};

export default CollectionGallerySection;
