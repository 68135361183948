import React, { useEffect, useState } from 'react';
import { Portal, Stack } from '@mantine/core';
import { NextRouter, useRouter } from 'next/router';
import { Breadcrumbs, Container, Divider, Group, LoadingOverlay } from '@ui/components/core';
import {
  ScrollToTopButton,
  SortMenu,
  Text,
  FilterImageCarousel,
  TruncateText,
  FilterChipCarousel,
  SimplePagination,
} from '@ui/components/shared';
import { responsiveStyles } from '@ui/components/styles';
import CollectionFilterSection from '../CollectionFilterSection/CollectionFilterSection';
import CollectionGallerySection from '../CollectionGallerySection/CollectionGallerySection';
import { FacetModel, sortFacets } from './functions';
import { Root } from '@ui/types/mappings/Collections';
import { FilterImageCarouselMappings, useStaticContent } from '@ui/providers/static-content-provider';
import headings from '@content/data/seo/headings';
import { CollectionBanner, CollectionEmptySection, CollectionBannerSection } from '@ui/components/features';
import { BreadcrumbLink } from '@ui/types/mappings/BreadcrumbMappings.type';
import Link from 'next/link';

type PageData = {
  query: string;
  collection: string;
  filters: string[];
  page: number;
  sort: string;
};

type CollectionDetailsSectionProps = {
  seoTitle?: string;
  data: Root;
  onFiltersChange?: (pageData: PageData) => void;
  onSortChange?: (pageData: PageData) => void;
  collectionBanner?: CollectionBanner | null;
  breadcrumbs?: BreadcrumbLink[];
  collectionHeading?: string | null;
  collectionTopText?: string | null;
  collectionBottomText?: string | null;
  collectionMenuLinks?: string | null;
};

type Headings = typeof headings;

const promoMapping = {
  'category_promo-70': '70% OFF',
  'category_promo-50': '50% OFF',
  'category_promo-30': '30% OFF',
};

const generateTitle = (router: NextRouter, headings: Headings) => {
  if (router.query.collections?.includes('sale')) {
    return `WOMEN'S CLOTHING SALE`;
  }
  if (Array.isArray(router.query.collections)) {
    if (router.query.collections.length === 1) {
      return (
        headings[router.query.collections[0] as keyof Headings] &&
        headings[router.query.collections[0] as keyof Headings].find((item) => !item.filter)?.h1
      );
    }
    if (router.query.collections.length === 2) {
      for (const promo of Object.keys(promoMapping)) {
        if (router.query.collections[1].includes(promo)) {
          if (
            headings[router.query.collections[0] as keyof Headings] &&
            headings[router.query.collections[0] as keyof Headings].find((item) => !item.filter)?.h1
          ) {
            return (
              headings[router.query.collections[0] as keyof Headings].find((item) => !item.filter)?.h1 +
              ' ' +
              promoMapping[promo as keyof typeof promoMapping]
            );
          }
          promoMapping[promo as keyof typeof promoMapping];
        }
      }
      if (router.query.collections[1].includes('category_promo-70')) return `70% OFF`;
      if (router.query.collections[1].includes('category_promo-50')) return `50% OFF`;
      if (router.query.collections[1].includes('category_promo-30')) return `30% OFF`;
      const filter = router.query.collections[1].split('_')[0];
      const pageName = router.query.collections[1].split('_')[1];
      return (
        headings[router.query.collections[0] as keyof Headings] &&
        headings[router.query.collections[0] as keyof Headings].find(
          (item) => item.filter === filter && item.pageName.toLowerCase() === pageName.toLowerCase(),
        )?.h1
      );
    }

    return undefined;
  }
};

const CollectionDetailsSection = ({
  data,
  breadcrumbs: breadcrumbsProp = [],
  collectionHeading = null,
  collectionTopText = null,
  collectionBottomText = null,
  collectionMenuLinks = null,
  collectionBanner = null,
}: CollectionDetailsSectionProps): React.ReactElement => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [facets, setFacets] = useState(sortFacets(data.facets || []).map(FacetModel));
  const filterImageCarouselMappings:FilterImageCarouselMappings = useStaticContent('Collection.FilterImageCarouselMappings');
  const filterImageCarouselData = filterImageCarouselMappings.find((item) => item.handle === router.asPath);

  const responsive = responsiveStyles();
  generateTitle(router, headings);
  useEffect(() => {
    setIsLoading(true);
  }, [data]);

  useEffect(() => {
    setFacets(sortFacets(data.facets || []).map(FacetModel));
    setIsLoading(false);
  }, [data]);

  const sortingOptions = (data.sortingOptions || [])
    .filter((option) => option.label !== 'Default')
    .map((option, i) => (i === 0 ? { ...option, field: 'relevance' } : option));


  const nextPage = () => {
    const page = data.page + 1;
    router.push({pathname: router.pathname, query: { ...router.query, page }});
  }

  const navigatePage = (page: number) => {
    if(page === 1) {
      const { page, ...restQuery } = router.query; // Remove the `page` query parameter
      router.push({pathname: router.pathname,query: restQuery});
    } else {
      router.push({pathname: router.pathname, query: { ...router.query, page }});
    }
  }

  const prevPage = () => {
    const page = data.page - 1;
    if(page === 1) {
      const { page, ...restQuery } = router.query; // Remove the `page` query parameter
      router.push({pathname: router.pathname,query: restQuery});
    } else {
      router.push({pathname: router.pathname, query: { ...router.query, page }});
    }
  }

  return (
    <div>
      {isLoading && (
        <Portal>
          <LoadingOverlay pos="fixed" bg="rgba(255,255,255,0.5)" zIndex={9999} visible />
        </Portal>
      )}
      <Container
        px={{ base: 0, md: '2rem' }}
        pt={{ base: '0.875rem', md: '1.5rem' }}
        pb={{ base: '1.125rem', md: '1.25rem' }}
        sx={(theme) => ({
          [theme.fn.largerThan('md')]: {
            display: data.results && data.results.length ? 'block' : 'none',
          },
        })}
      >
        <Group
          px="1rem"
          pb={data.results && data.results.length > 0 ? '1rem' : '0'}
          className={responsive.classes.hiddenDesktop}
        >
          {data.collection && (
            <Breadcrumbs
              classNames={{
                root: 'py-0',
                breadcrumb: 'text-[rgba(0,0,0,0.67)] text-[10px] tracking-[0.55px] capitalize',
                separator: 'text-[rgba(0,0,0,0.67)] text-[10px] mx-1.5',
              }}
            >
              {breadcrumbsProp.map(({ label, href }) => (
                  <Link key={label} href={href} color="black.8" aria-label={label}>
                    {label}
                  </Link>
                ))
              }
            </Breadcrumbs>
          )}
          {data.query && (
            <Text fz={{ base: '10px', md: '13px' }} lts="0.055em" tt="uppercase" color="black.8">
              Search results for &quot;{data.query}&quot;
            </Text>
          )}
        </Group>
        {collectionBanner ? (
          <div className="pb-8">
            <CollectionBannerSection {...collectionBanner} />
          </div>
        ): data.collection && filterImageCarouselData ? (
          <FilterImageCarousel
            links={filterImageCarouselData.links || []}
            title={filterImageCarouselData.sectionTitle}
            className="mb-6 ml-4 md:mb-10 md:ml-0"
            swiperOptions={{
              slidesPerView: 2.5,
              spaceBetween: 16,
              breakpoints:{
                500: {
                  slidesPerView: 4.5,
                },
                768: {
                  slidesPerView: 5.5,
                },
                1024: {
                  slidesPerView: 6.5,
                  spaceBetween: 24,
                },
                1200: {
                  slidesPerView: 7,
                }}
              }
            }
          />
        ): null}
        <FilterChipCarousel />
        {facets.length > 0 && (
          <CollectionFilterSection
            facets={facets}
            sort={data.sort || 'relevance=desc'}
            sortingOptions={sortingOptions}
          />
        )}
      </Container>
      {facets.length > 0 && (
        <Divider color="black.8" className={responsive.classes.hiddenMobile} sx={{ borderTopWidth: '0.5px' }} />
      )}
      <Container px={{ base: '1rem', md: '2rem' }} pb={data.results && data.results.length > 0 ? '3rem' : '0'}>
        {data.collection ? (
          <Stack p={{ base: '0.125rem 0 1rem 0', md: '1.5rem 0 1rem 0' }} spacing="0.5rem">
            {!isLoading && collectionTopText && (
              <TruncateText menuLinks={collectionMenuLinks?.replace(/<\/?p>/g, ',').split(',')}>
                {collectionTopText}
              </TruncateText>
            )}
            <Breadcrumbs
              classNames={{
                root: 'hidden md:flex',
                breadcrumb: 'text-[rgba(0,0,0,0.67)] text-[10px] tracking-[0.55px] capitalize',
                separator: 'text-[rgba(0,0,0,0.67)] text-[10px] mx-1.5',
              }}
            >
              {breadcrumbsProp.map(({ label, href }) => (
                <Link key={label} href={href}>
                  {label}
                </Link>
              ))}
            </Breadcrumbs>
            <Group position="apart" align="flex-end">
              <h1>
                <Text className="text-[13px] tracking-[0.4px] md:text-[15px] md:tracking-[0.1px] uppercase font-bold">
                  {collectionHeading}
                </Text>
              </h1>
              <Text className="text-xs text-[rgba(0,0,0,0.37)]">{data.totalResults} items found</Text>
              <Group spacing={0} className={`${responsive.classes.hiddenMobile} -mb-3`}>
                <Divider orientation="vertical" my="0.5rem" color="black" />
                <SortMenu sort={data.sort || 'relevance=desc'} sortingOptions={sortingOptions} />
              </Group>
            </Group>
          </Stack>
        ) : (
          <div className="pt-0 md:pt-6 pb-4 space-y-2">
            <div className="hidden md:block text-[rgba(0,0,0,0.67)] text-[10px] tracking-[0.55px]">
              SEARCH RESULTS FOR
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 items-end">
              <Text className="text-[13px] tracking-[0.4px] md:text-[15px] md:tracking-[0.1px] uppercase font-normal truncate">
                &quot;{data.query}&quot;
              </Text>
              <div className="text-[10px] text-right md:text-center text-[rgba(0,0,0,0.37)]">
                {data.totalResults} items found
              </div>
              <div className="hidden md:flex items-center justify-end -mb-3">
                <Divider orientation="vertical" my="0.5rem" color="black" />
                <SortMenu sort={data.sort || 'relevance=desc'} sortingOptions={sortingOptions} />
              </div>
            </div>
          </div>
        )}
        {!!(!isLoading && data.results && data.results.length > 0) && (
          <>
            <CollectionGallerySection collection={data.collection} products={data.results} />
            <div className="flex justify-center mt-6 md:mt-12">
              <SimplePagination currentPage={data.page} totalPages={data.totalPages} handleNextPage={nextPage} handlePreviousPage={prevPage} setPage={navigatePage}/>
            </div>
            {!isLoading && collectionBottomText && collectionBottomText.trim().length > 0 && (
              <>
                <Divider className="mt-6 mb-2" />
                <TruncateText>{collectionBottomText}</TruncateText>
              </>
            )}
          </>
        )}
      </Container>
      {!isLoading && data.results && data.results.length === 0 && <CollectionEmptySection />}
      <ScrollToTopButton />
    </div>
  );
};

CollectionDetailsSection.defaultProps = {
  onFiltersChange: undefined,
  onPageChange: undefined,
  onSortChange: undefined,
};

export default CollectionDetailsSection;
